<template>
    <!--用户信息 -->
    <div class="modify-phone">
        <div class="main bg-fff">
            <div class="inp-box">
                <div class="phone-item">
                    <span>请输入原手机号</span>
                    <div class="phone">
                        <input type="number" maxlength="11" v-model="oldPhone" disabled="true" />
                    </div>
                </div>
				<div class="phone-item">
                    <span>请输入新手机号</span>
                    <div class="phone">
                        <input type="number" maxlength="11" v-model="phone" />
                    </div>
                </div>
                <div class="item">
                    <div class="inp code">
                        <input
                            type="number"
                            placeholder="请输入新手机验证码"
                            maxlength="6"
                            v-model="authCode"
                        />
                        <span @click="getVerifyCode" v-show="codeShow"
                            >获取验证码</span
                        >
                        <span @click="getVerifyCode" v-show="!codeShow"
                            >{{ timeDown }}s</span
                        >
                    </div>
                </div>
                <div class="btn" @click="modifyPhone">
                    <button>确定修改</button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { updatePhone } from "@/api/user";
import {httpPost} from "@/utils/fetch";
import { removeAllUserInfo,getUserinfo } from '@/utils/auth'
export default {
    components: {
    },
    computed: {
    },
    data() {
        return {
            title: "修改手机",
            backVisible: true,
            userInfo: {},
			timeDown:60,
			oldPhone:"",
			phone:"", //新手机号码
			codeShow:true,
			authCode:"",
        };
    },
    methods: {
		getVerifyCode(){
			if(!this.phone){ 
                this.$toast({
                    message: "手机号码不能为空"
                })
				return false; 
			} 
			let reg = /^[1][3,4,5,6.7,8,9][0-9]{9}$/;
			if(!reg.test(this.phone)){ 
                this.$toast({
                    message: "手机号码格式有误"
                })
				return false; 
			} 
			if(this.phone == this.oldPhone){
                this.$toast({
                    message: "请输入新手机号码!"
                })
				return false; 
			}
			if(this.codeShow){
				this.codeShow =  false;
				httpPost('/customer/code',{
					phone:this.phone
				}).then(res=>{
					console.log(res);
				})

				console.log(111233);
				this.codeDownTimer();
			}
			
		},
		codeDownTimer(){
			let timer = setInterval(() => {
				this.timeDown--;
				if (this.timeDown <= 0) {
					this.codeShow = true;
					this.timeDown = 60;
					clearInterval(timer);
				}
			}, 1000);

		},
        modifyPhone() {
            var data = {
				phone:this.phone,
				code:this.authCode,
			}	
			if(!this.authCode){ 
                this.$toast({
                    message: "验证码不能为空!"
                })
				return false; 
			} 	
			updatePhone(data).then(res=>{
				let {status,statusMessage} = res;
				if(status){
					this.$store.dispatch('user/loginOut');
					this.$router.replace({
						path:"/login"
					})
					
				}else{
                    this.$toast({
                        message: statusMessage
                    })
				}
			}).catch(res=>{
				console.log(res);
			})
        },
    },
    created(){
        this.userInfo = JSON.parse(getUserinfo());
		this.oldPhone = this.userInfo.phone;
    },
};
</script>

<style scoped>
.modify-phone{
    padding-top: 50px;
}
.modify-phone .main {
    padding: 5px 18px 0 18px;
}
.inp-box{
	/* position: absolute;
	top: 52%;
	left: 31.5px;
	z-index: 10;
	width: 314px;	 */
}
 .inp-box .item,.phone-item{
	height: 64.5px;
	border-bottom: 1px solid #EDF1F4;
}
 .inp-box  input{
	/* color: #F8F8FD; */
}
 .inp-box .item{
	padding-top: 29px;
}
 .inp-box .item .inp{
	height: 16px;
}
 .inp-box .inp.code{
	display: flex;
	justify-content: space-between;
	align-items: center;
}
 .inp-box .inp.code span{
	font-size: 14px;
	color: #116ED9;
	padding-left: 14px;
	margin-right: 8px;
	cursor: pointer;
	border-left: 1px solid #5765A5;
}
 .inp-box .phone-item{
	padding-top: 3px;
}
 .inp-box .phone-item span{
	font-size: 13px;
	/* color: #8492D1; */
}
 .inp-box .phone-item .phone{
	margin-top: 6px;
}
 .inp-box .btn{
	width: 314px;
	margin: 10% auto;
	margin-bottom: 0;
}
 .inp-box .btn button{
	width: 100%;
	height: 48px;
	background: #3A4B98;
	color: #fff;
	text-align: center;
	line-height: 48px;
	border-radius: 24px;
    border: none;
}
 .inp-box .btn button:active{
	opacity: 0.8;
}
 .inp-box .btn button:focus{
	opacity: 0.8;
}
</style>